<template>
  <div
    :class="[
      'generic-page',
      colorModeClass,
      backgroundColorClass,
      {
        '--with-fitter-badge': showFitterBadge,
        '--content-type-workout': isWorkoutPage,
        '--with-comments': is_commentable
      }
    ]"
  >
    <toolbar
      v-if="showToolbar"
      :transparent="renderFixedToolbar"
      :animate-bg-to-white="isWorkoutPage"
      :use-account-styles="useGlobalToolbar"
      :back="isHomepage ? false : is_root ? !!show_back_button : true"
      :emit-on-exit-attempt="isWorkoutPage"
      :logo="useGlobalToolbar"
      fixed
      with-menu
      @exitAttempted="exitAttempted"
    >
      <template v-slot:right="{ textColor }">
        <template v-if="captureFormId">
          <submissions-button
            :form-id="captureFormId"
            :title="page_title"
            :is-missable="isWorkoutPage"
          />

          <div id="toolbar-submission-button-portal" />
        </template>

        <scheduling-button
          item-type="page"
          :item-id="page_id"
          :page-content-type="page_content_type"
          :title="page_title"
          :can-add-to-schedule="show_add_to_schedule_button"
        />

        <bookmark-button
          v-if="isHomepage ? false : show_bookmark_button"
          :page-id="page_id"
          :style="useGlobalToolbar ? { color: textColor } : {}"
        />

        <div id="page-toolbar-actions" />
      </template>
    </toolbar>

    <template v-if="showPageContent">
      <blocks-container
        :blocks="blocks"
        :page-id="page_id"
        @init="handleContainerInit"
      />

      <cta-row
        v-if="showCtaRow"
        :key="page_id"
        white-bg
        overlay
        :classes="isWorkoutPage ? 'generic-page__cta-row--workout-offset' : ''"
      >
        <div class="w-100">
          <template v-if="is_commentable || isPresent(showThread)">
            <message-thread
              :key="threadId"
              class="generic-page__message-thread"
              :placeholder="
                $t(
                  'components.pageTemplates.genericTemplate.addCommentPlaceHolder'
                )
              "
              collapsable
              with-participants
              is-comment-thread
              :message-thread-id="threadId"
            />
          </template>
        </div>
        <template v-if="displayablePageActions.length > 0">
          <template v-for="action in displayablePageActions">
            <template
              v-if="action.action_type === PAGE_ACTION_TYPES.MARK_COMPLETE"
            >
              <mark-complete
                :key="action.id"
                :block-action="action.block_action"
                :page-title="page_title"
                :page-id="page_id"
                :root-id="root_id"
              />
            </template>

            <template
              v-else-if="
                action.action_type === PAGE_ACTION_TYPES.GENERIC_BUTTON
              "
            >
              <generic-button
                :key="action.id"
                :block-action="action.block_action"
                :text="action.text"
                :post-purchase-text="action.post_action_button_text"
              />
            </template>

            <template
              v-else-if="action.action_type === PAGE_ACTION_TYPES.START"
            >
              <start
                :key="action.id"
                :page-title="page_title"
                :page-id="page_id"
                :text="action.text"
                :schedule="schedule"
                :access="page_access"
                :page-type="page_content_type"
                :action="action"
                :block-action="action.block_action"
                :post-action-button-text="action.post_action_button_text"
              />
            </template>
          </template>
        </template>

        <!-- <page-siblings v-if="siblings.length > 1" :pages="siblings" /> -->
      </cta-row>
    </template>

    <template
      v-else-if="!hasFetchedAccess || (!hasCheckedAuthentication && isLocked)"
    >
      <page-loader />
    </template>

    <template v-else>
      <div class="container">
        <div
          class="text-center h-full px-3 d-flex flex-column justify-content-center"
        >
          <v-icon icon="lock" class="text-grey-50" size="3rem" />
          <h2 class="mb-0">
            {{ $t("components.pageTemplates.genericTemplate.notAccessTitle") }}
          </h2>

          <template v-if="isLockedByPrivate">
            <p class="lead mt-2 mb-5">
              {{
                $t("components.pageTemplates.genericTemplate.loginToHaveAccess")
              }}
            </p>
          </template>
          <template v-else-if="isLockedFromPurchase">
            <p class="lead mt-2 mb-5">
              {{
                $t("components.pageTemplates.genericTemplate.purchaseToUnlock")
              }}
            </p>
          </template>
          <template v-else>
            <p class="lead mt-2 mb-5">
              {{
                $t(
                  "components.pageTemplates.genericTemplate.loginToReviewContent"
                )
              }}
            </p>
          </template>
          <v-button
            v-if="isLockedFromPurchase"
            block
            @click="triggerPurchase"
            class="mb-2"
          >
            {{ $t("components.pageTemplates.genericTemplate.purchase") }}
          </v-button>

          <v-button
            v-if="!isAuthenticated"
            secondary
            @click="() => $router.push('#auth')"
          >
            {{ $t("components.pageTemplates.genericTemplate.login") }}
          </v-button>
        </div>
      </div>
    </template>

    <page-action-panel-modal :page-id="page_id" />

    <v-made-with-movement
      v-if="!isDesktop"
      :auto-detect-color="!!background_color"
    />
  </div>
</template>

<script>
import { mapState, mapMutations, mapGetters, mapActions } from "vuex"

import {
  getOngoingOrUpcomingSchedule,
  getLastSchedule
} from "@/lib/schedule-utils"
import { STAGES as CHECKOUT_STAGES } from "@/lib/checkout-utils"
import isPreviewContext from "@/lib/is-platform/is-preview-context"
import { isPresent, isEmpty, isBuilderContext } from "@/lib/utils"
import enumValidator from "@/lib/validators/enum-validator"
import EventBus, { EVENTS } from "@/lib/event-bus"
import { pageMeta } from "@/lib/pwa-meta"
import { QUERY_PARAMS } from "@/lib/messages-utils"
import { HIDDEN_ELEMENTS } from "@/lib/constants"
import {
  BLOCK_TYPES,
  COLOR_MODES,
  ALL_COLORS,
  BACKGROUND_COLORS
} from "@/lib/stream-utils"

import Toolbar from "@/components/Toolbar"
import PlaceholderRect from "@/components/PlaceholderRect"

import { getPageAccess, findAccessRoot, REASONS } from "@/lib/access-utils"

const PAGE_ACTION_TYPES = {
  MARK_COMPLETE: "mark_complete",
  GENERIC_BUTTON: "generic_button",
  START: "start"
}

const PageLoader = {
  functional: true,
  render(h) {
    return h("div", { class: "container pt-1" }, [
      h(PlaceholderRect, { props: { width: "100%", height: "250px" } }),
      h(PlaceholderRect, { props: { width: "80%", height: "32px" } }),
      h(PlaceholderRect, { props: { width: "35%", height: "32px" } })
    ])
  }
}

export const withLoader = component => ({
  component,
  loading: PageLoader
})

export default {
  components: {
    BlocksContainer: () =>
      withLoader(import("@/components/stream/BlocksContainer")),
    Toolbar,
    PageLoader,
    CtaRow: () => import("@/components/CtaRow"),
    // PageSiblings: () => import("@/components/toolbar/PageSiblings"),
    PageActionPanelModal: () =>
      import("@/components/modals/PageActionPanelModal"),
    BookmarkButton: () => import("@/components/toolbar/BookmarkButton"),
    SchedulingButton: () => import("@/components/toolbar/SchedulingButton"),
    SubmissionsButton: () => import("@/components/toolbar/SubmissionsButton"),
    Start: () => import("@/components/page-actions/Start"),
    MarkComplete: () => import("@/components/page-actions/MarkComplete"),
    GenericButton: () => import("@/components/page-actions/GenericButton"),
    MessageThread: () => import("@/components/message-thread/Thread")
  },

  props: {
    page_id: { type: [Number, String], required: true },
    root_id: { type: [Number, String], default: null },
    page_title: { type: String, default: "" },
    page_content_type: { type: String, default: null },
    page_data: { type: Object, default: () => ({}) }, // TODO: Remove
    attributes: { type: Object, default: () => ({}) },
    page_access: { type: Object, default: () => ({}) },
    page_schedule: { type: Object, default: () => ({}) }, // Legacy cache support (remove when schedules are receive by making a request)
    page_schedules: { type: Array, default: null },
    page_actions: { type: Array, default: () => [] },
    siblings: { type: Array, default: () => [] },
    blocks: { type: Array, required: true },
    references: { type: Array, default: () => [] },
    is_root: { type: Boolean, default: false },
    show_back_button: { type: Boolean, default: true },
    show_bookmark_button: { type: Boolean, default: true },
    show_add_to_schedule_button: { type: Boolean, default: false },
    show_bottom_padding: { type: Boolean, default: true },
    show_tabbar: { type: Boolean, default: true },
    show_view_switcher: { type: Boolean, default: false },
    color_mode: { type: String, default: "" },
    background_color: {
      type: String,
      default: null,
      validator: enumValidator([null, ...Object.values(ALL_COLORS)])
    },
    use_global_toolbar: { type: Boolean, default: false },
    og_title: { type: String, default: "" },
    og_description: { type: String, default: "" },
    og_image: { type: Object, default: null },
    is_commentable: { type: Boolean, default: false },
    message_thread_id: { type: [String, Number], default: null }, // TODO: Remove
    content_root_id: { type: [Number, String], default: null }
  },

  data() {
    return {
      showThread: null,
      hasFetchedAccess: false,
      BACKGROUND_COLORS,
      PAGE_ACTION_TYPES
    }
  },

  provide() {
    return {
      page_id: this.page_id,

      $references: () => {
        // TODO
        //
        // The `references` prop provides an array of Page IDs for all
        // pages that are referenced by the collection blocks on the page.
        //
        // We can use this array of ID's to fetch the most up to date data
        // from the API.
        //
        // Then we create an indexed object from this response, and this
        // is read by `getBlockItems` ensure the latest data is displayed on the page
        return {}
      }
    }
  },

  head() {
    return pageMeta({
      title: isPresent(this.og_title) ? this.og_title : this.page_title,
      ogTitle: isPresent(this.og_title) ? this.og_title : this.page_title,
      url: this.global?.app.url + this.page_data["page.path"],
      description: isPresent(this.og_description) ? this.og_description : null,
      image: isPresent(this.og_image) ? this.og_image.image : null
    })
  },

  computed: {
    ...mapState(["global"]),
    ...mapState("responsive", ["isDesktop"]),
    ...mapState("pages", ["pageSubscriptions", "pageProgress"]),
    ...mapGetters("pages", ["isToolbarHidden", "submissionsCountByPage"]),
    ...mapGetters("auth", [
      "hasCheckedAuthentication",
      "isAuthenticated",
      "currentUser"
    ]),
    ...mapGetters("access", ["isSubscriberOnly"]),
    ...mapGetters(["showFitterBadge", "appAccess"]),
    ...mapGetters("workouts", ["workoutIsFollowAlongVisible"]),
    ...mapGetters("router", ["stack"]),
    ...mapGetters("ui", ["isElementHidden"]),

    isReady() {
      return this.hasCheckedAuthentication && this.hasFetchedAccess
    },

    showPageContent() {
      if (isPreviewContext() || isBuilderContext()) {
        return true
      }

      return !!this.pageAccess.granted
    },

    pageAccess() {
      if (isBuilderContext()) return { granted: true }

      return getPageAccess({
        pageId: this.page_id,
        currentPageId: this.page_id,
        currentUser: this.currentUser,
        appAccess: this.appAccess,
        pageSubscriptions: this.pageSubscriptions,
        pageProgresses: this.pageProgress
      })
    },

    isLocked() {
      return !this.pageAccess.granted
    },

    isLockedFromAuthentication() {
      return (
        this.isLocked &&
        this.pageAccess.reason === REASONS.REQUIRE_AUTHENTICATION
      )
    },

    isLockedFromPurchase() {
      return (
        this.isLocked && this.pageAccess.reason === REASONS.REQUIRE_PURCHASE
      )
    },

    isLockedByPrivate() {
      return this.isLocked && this.pageAccess.reason === REASONS.IS_PRIVATE
    },

    isGlobalInheritance() {
      return !findAccessRoot(this.page_id, this.page_id)[0]
    },

    showCtaRow() {
      return (
        this.is_commentable ||
        isPresent(this.showThread) ||
        this.displayablePageActions.length > 0 ||
        (this.siblings.length > 0 &&
          (this.isWorkoutPage ? !this.workoutIsFollowAlongVisible : true))
      )
    },

    displayablePageActions() {
      return this.isWorkoutPage
        ? []
        : this.page_actions.filter(a => a.action_type !== "track_progress")
    },

    useGlobalToolbar() {
      return this.is_root ? !!this.use_global_toolbar : false
    },

    isHomepage() {
      return this.is_root && this.$route.path === "/"
    },

    submissionsCount() {
      return this.submissionsCountByPage(this.page_id)
    },

    schedule() {
      const schedules =
        this.page_schedules || [this.page_schedule].filter(s => s) || []

      return (
        getOngoingOrUpcomingSchedule(schedules) || getLastSchedule(schedules)
      )
    },

    showToolbar() {
      if (
        this.isElementHidden(HIDDEN_ELEMENTS.TOOLBAR) &&
        this.stack[0] === this.$route.path
      )
        return false

      if (this.isWorkoutPage && this.isToolbarHidden(this.page_id)) {
        return false
      }

      return this.isHomepage && this.isDesktop
        ? false
        : this.isHomepage
        ? isPresent(this.global) &&
          isPresent(this.global.toolbar) &&
          !this.global.toolbar.is_hidden
        : true
    },

    // TODO: Remove all Workout Related code from this component
    // <--- Workout Related
    renderFixedToolbar() {
      // TODO: think of better solution to change it for follow along
      return this.isWorkoutPage
        ? !this.workoutIsFollowAlongVisible
        : !this.useGlobalToolbar
    },

    workoutBlock() {
      return this.blocks.find(b => b.type === BLOCK_TYPES.WORKOUT_BLOCK)
    },

    captureFormId() {
      if (this.isWorkoutPage) {
        if (this.workoutBlock && this.workoutBlock.data) {
          return this.workoutBlock.data.workout.capture_form_id
        }
      }

      return null
    },

    isWorkoutPage() {
      return (
        this.page_content_type === "workout" && isPresent(this.workoutBlock)
      )
    },
    // --->

    colorModeClass() {
      return isEmpty(this.color_mode)
        ? ""
        : this.color_mode === COLOR_MODES.DARK
        ? "--dark-mode"
        : "--light-mode"
    },

    backgroundColorClass() {
      return this.background_color
        ? `--bg-${this.background_color}`
        : "--bg-no-fill"
    },

    isDarkBackground() {
      return this.background_color === ALL_COLORS.BLACK
    },

    backgroundColor() {
      return BACKGROUND_COLORS[this.background_color]
    },

    threadId() {
      return isPresent(this.message_thread_id)
        ? this.message_thread_id
        : this.showThread
    }
  },

  activated() {
    this.setPage()

    this.$nextTick(() => {
      EventBus.$emit(EVENTS.PAGE_BLOCKS_RENDERED)
    })
  },

  mounted() {
    this.setPage()

    this.loadAccess({ pageId: this.page_id }).then(() => {
      this.hasFetchedAccess = true
    })

    // Evaluate on mount to prevent thread from disappearing when the query param is removed
    this.showThread = this.$route?.query[QUERY_PARAMS.SHOW_THREAD]

    EventBus.$on(
      EVENTS.EXISTING_PRODUCT_SUBSCRIPTION,
      this.handleExistingProductSubscription
    )

    EventBus.$on(EVENTS.CHECKOUT_COMPLETED, this.handleCheckoutSuccess)
  },

  beforeDestroy() {
    EventBus.$off(
      EVENTS.EXISTING_PRODUCT_SUBSCRIPTION,
      this.handleExistingProductSubscription
    )

    EventBus.$off(EVENTS.CHECKOUT_COMPLETED, this.handleCheckoutSuccess)
  },

  methods: {
    isPresent,

    ...mapMutations("pages", ["setPageAttributes", "setCurrentPage"]),
    ...mapActions("pages", ["loadAccess"]),
    ...mapActions("pageActionPanel", ["triggerAction"]),

    handleContainerInit() {
      this.$nextTick(() => {
        this.$emit("blocksRendered")
        EventBus.$emit(EVENTS.PAGE_BLOCKS_RENDERED)
      })
    },

    exitAttempted() {
      EventBus.$emit(EVENTS.PAGE_EXIT_ATTEMPTED, { pageId: this.page_id })
    },

    triggerPurchase() {
      EventBus.$emit(EVENTS.TRIGGER_CHECKOUT, {
        productIds: this.pageAccess.data.product_ids,
        overlay: this.pageAccess.data.purchase_overlay
      })
    },

    setPage() {
      this.setCurrentPage({
        id: this.page_id,
        rootId: this.root_id,
        title: this.page_title,
        contentRootId: this.content_root_id,
        path: this.page_data["page.path"],
        actions: this.page_actions,
        access: this.page_access,
        type: this.page_content_type,
        show_tabbar: this.show_tabbar,
        show_view_switcher: this.show_view_switcher
      })

      this.setPageAttributes({
        pageId: this.page_id,
        attributes: this.attributes
      })
    },

    showAuthModal() {
      if (!this.$route.hash?.startsWith("#auth")) {
        this.$router.push("#auth")
      }
    }
  },

  watch: {
    isReady: {
      immediate: true,
      handler: function() {
        if (this._inactive) return
        if (!this.isReady) return

        if (
          this.$route.hash &&
          this.$route.hash.startsWith(CHECKOUT_STAGES.START)
        )
          return

        if (this.isLockedFromPurchase) {
          // This flow shows the auth modal before the purchase modal
          // if the user is logged out and we are using global inheritance
          const showAuthFirst =
            this.isGlobalInheritance &&
            this.appAccess.prompt_purchase_post_sign_up &&
            this.isSubscriberOnly

          if (EventBus.hasListener(EVENTS.POSTING_SIGN_UP_ACTION)) {
            // Bind event from AuthModal
            EventBus.$once(EVENTS.FINISH_SIGN_UP_ACTION, this.triggerPurchase)
          } else if (showAuthFirst && !this.isAuthenticated) {
            this.showAuthModal()
          } else if (!(this.$router.hash || "").startsWith("#auth")) {
            this.triggerPurchase()
          }
        } else if (this.isLockedFromAuthentication) {
          this.showAuthModal()
        }
      }
    },

    showPageContent() {
      if (this.showPageContent) {
        this.$nextTick(() => {
          window.scroll(0, 0)
        })
      }
    },

    show_tabbar: {
      immediate: true,
      handler() {
        this.setPage()
      }
    },

    attributes: {
      immediate: true,
      deep: true,
      handler() {
        this.setPageAttributes({
          pageId: this.page_id,
          attributes: this.attributes
        })
      }
    }
  }
}
</script>

<style lang="scss">
@import "@/assets/styles/variables";
@import "@/assets/styles/mixins";

.generic-page {
  background: $color-ui--background;
  color: $color-ui--grey-90;

  &.--content-type-workout {
    padding-bottom: 80vh;

    &.--with-fitter-badge {
      padding-bottom: 60vh;
    }
  }

  &.--bg-white {
    @include bg-white;

    & > .blocks-container {
      @include bg-white;
    }
  }

  &.--bg-black {
    @include bg-black;

    & > .blocks-container {
      @include bg-black;
    }
  }

  &.--bg-light_grey {
    @include bg-light-grey;

    & > .blocks-container {
      @include bg-light-grey;
    }
  }

  &.--bg-grey {
    @include bg-dark-grey;

    & > .blocks-container {
      @include bg-dark-grey;
    }
  }

  &.--bg-primary {
    @include bg-primary;

    & > .blocks-container {
      @include bg-primary;
    }
  }

  &.--bg-secondary {
    @include bg-secondary;

    & > .blocks-container {
      @include bg-secondary;
    }
  }

  &.--bg-dark {
    @include bg-dark;

    & > .blocks-container {
      @include bg-dark;
    }
  }

  &.--bg-light {
    @include bg-light;

    & > .blocks-container {
      @include bg-light;
    }
  }

  // Safe Space Top - No Toolbar
  & > .blocks-container {
    & > .block:first-child {
      padding-top: env(safe-area-inset-top);

      &[data-type="hero_block"],
      &[data-type="livestream_block"] {
        padding-top: inherit;
      }
    }
  }

  // Safe Space Top - Inline Toolbar
  & > [data-role="inline-toolbar"] + .blocks-container,
  &
    > [data-role="fixed-toolbar"]:not([data-transparent-toolbar])
    + .blocks-container {
    & > .block:first-child {
      padding-top: inherit;
    }
  }

  // Safe Space Top - Fixed Toolbar
  // Max 5 levels of nesting supported
  & > [data-transparent-toolbar] + .blocks-container {
    & > .block:first-child,
    &
      > .block:first-child[data-type="composed_block"]
      > [data-role="block-container"]
      > .block:first-child, // .block:first-child > composed-block > .block
    &
      > .block:first-child[data-type="composed_block"]
      > [data-role="block-container"]
      > .block:first-child[data-type="composed_block"]
      > [data-role="block-container"]
      > .block:first-child, // .block:first-child > composed-block > composed-block > .block
    &
      > .block:first-child[data-type="composed_block"]
      > [data-role="block-container"]
      > .block:first-child[data-type="composed_block"]
      > [data-role="block-container"]
      > .block:first-child[data-type="composed_block"]
      > [data-role="block-container"]
      > .block:first-child, // .block:first-child > composed-block > composed-block > composed-block > .block
    &
      > .block:first-child[data-type="composed_block"]
      > [data-role="block-container"]
      > .block:first-child[data-type="composed_block"]
      > [data-role="block-container"]
      > .block:first-child[data-type="composed_block"]
      > [data-role="block-container"]
      > .block:first-child[data-type="composed_block"]
      > [data-role="block-container"]
      > .block:first-child, // .block:first-child > composed-block > composed-block > composed-block > composed-block > .block
    &
      > .block:first-child[data-type="composed_block"]
      > [data-role="block-container"]
      > .block:first-child[data-type="composed_block"]
      > [data-role="block-container"]
      > .block:first-child[data-type="composed_block"]
      > [data-role="block-container"]
      > .block:first-child[data-type="composed_block"]
      > [data-role="block-container"]
      > .block:first-child[data-type="composed_block"]
      > [data-role="block-container"]
      > .block:first-child // .block:first-child > composed-block > composed-block > composed-block > composed-block > composed-block > .block
 {
      padding-top: 60px;
      padding-top: calc(60px + env(safe-area-inset-top));

      &[data-type="hero_block"],
      &[data-type="iframe_block"],
      &[data-type="livestream_block"],
      &[data-type="map_block"],
      &[data-type="composed_block"] {
        padding-top: inherit;
      }

      &[data-type="image_block"] {
        padding-top: env(safe-area-inset-top);
      }

      &[data-type="hero_block"] {
        .hero {
          padding-top: env(safe-area-inset-top);
        }
      }
    }
  }
}

.generic-page__message-thread {
  &.message-thread {
    min-height: initial;
    max-height: 68vh;

    .overlay-pill {
      display: flex;
      flex-direction: row-reverse;
      color: $color-ui--grey-60;
      background: transparent;
      backdrop-filter: none;

      .v-icon {
        margin-left: 0.5rem;
      }
    }

    .thread__footer {
      border: none;
      margin: 0;
      width: 100%;
      box-shadow: none;
      border: 2px solid $color-ui--grey-30;
      max-width: initial;
    }

    .message-thread__content {
      padding-top: 0;
      padding-bottom: 0;
    }

    &.--comments {
      flex-direction: column;

      .message-thread__footer-wrapper {
        margin: 0 0 0.75rem;
        padding-top: 0;
      }
    }

    &.--thread {
      & > .message-thread__content-wrapper {
        padding-left: 0;

        .thread__line-vert {
          left: 16px;
          top: 42px;
          height: 100%;
        }

        .thread__corner {
          display: none;
        }

        .message-thread__content {
          padding-left: 1px;
        }
      }

      .message-thread__actions {
        margin-bottom: 0.5rem;

        .v-button {
          margin-left: 2px;
          margin-bottom: -4px;
        }
      }

      .thread__footer {
        .thread__line-vert {
          height: 12px;
          top: -10px;
          background: $color-ui--grey-20;
        }
      }
    }
  }
}

.generic-page__cta-row--workout-offset {
  transform: translateY(-68px) !important;
  z-index: 14 !important;
}

.action-pages_start-time {
  color: $color-ui--grey-80;
  font-weight: $font-weight--semi-bold;
  padding: $size--3 0 $size--3;
  margin-bottom: $size--2;
}
</style>
